module.exports = {
  defaultLanguageKey: "tc",
  languages: [
    {
      code: "en",
      name: "ENG",
    },
    {
      code: "tc",
      name: "中文",
    },
    {
      code: "ja",
      name: "日本語",
    },
  ],
}
